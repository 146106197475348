import React from 'react'
import LeftLayout from '../../components/UI/LeftLayout'
import RightLayout from '../../components/UI/RightLayout'
import MainLayout from '../../components/UI/MainLayout'
import { styled } from 'styled-components'

const questionAndAnswerData = [
  {
    number: 1,
    question: "Where can I apply for replacement of license plate?",
    answer: "You may apply for replacement of license plate to the nearest LTO office in your area."
  },
  {
    number: 2,
    question: "I already paid the replacement fee but my license plate is not yet available. What should I do?",
    answer: "Please inquire again after 30 to 60 days. The production of remaining replacement plates is still on-going."
  },
  {
    number: 3,
    question: "I cannot find the official receipt",
    answer: "Kindly bring a valid ID and your OLD License Plate"
  },
  {
    number: 4,
    question: "The vehicle owner is not available to pick-up the license plate",
    answer: "Please secure a signed authorization letter from vehicle owner with a copy of a valid ID with signature."
  },
  {
    number: 5,
    question: "I changed the venue of my vehicle registration",
    answer: "The new license plate is available on the same office you ordered it."
  },
]

const FAQs = () => {
  return (
    <MainLayout>
      <LeftLayout style={{ flex: 1 }}>
        <FAQsContainer>
          <p>Important:</p>
          <span>
            This website is ONLY for inquiries of the availability of LTO license plates replacement (from Green to White) which were paid and unclaimed from 2015.
          </span>
          <h2 style={{ marginLeft: -50, fontWeight: "600 !important" }}>Frequently Asked Questions</h2>
          {
            questionAndAnswerData.map(res => {
              return (
                <div key={res.number}>
                  <span>
                    {res.number}. {res.question}
                  </span>
                  <li>
                    {res.answer}
                  </li>
                </div>
                )
            })
          }
      </FAQsContainer>
      </LeftLayout>
      <RightLayout style={{ width: 450 }}>
      </RightLayout >
    </MainLayout>
  )
}

export default FAQs

const FAQsContainer = styled.div`
    font-weight: 600;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 50px;
    color: black;
    font-size: 22px;

    @media only screen and (max-width: 800px){
        padding: 0px 0px 0px 0px;
        font-size: 16px;
        gap: 10px;
        margin-top: 40px;

        h2 {
          margin-left: 0px !important;
        }
    }
`