import React, { useRef, useState } from 'react'
import MainLayout from '../../components/UI/MainLayout'
import LeftLayout from '../../components/UI/LeftLayout'
import RightLayout from '../../components/UI/RightLayout'
import { styled } from 'styled-components'
import { Col, Radio, Row, Select, Spin, Typography } from 'antd'
import { checkPlateNumberAvailability } from '../../services/plateNumberApi'
import { CaretDownFilled } from '@ant-design/icons'
import { slicePortionText, slicePortionTextLtoGovPh } from '../../helper/sliceText'

const Home = () => {
  const [inputPlateNumber, setInputPlateNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [plateNumberAvailability, setPlateNumberAvailability] = useState(null);
  const [radioSelected, setRadioSelected] = useState("Plate Number");
  const [selectedValueOption, setSelectedValueOption] = useState("Green Plate Replacement")
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const selectFieldRef = useRef(null);

  const getPlateNumberAvailability =  async (e) => {
      e.preventDefault();
      setIsLoading(true);
      setIsSearch(true);
      try {
        const params = new URLSearchParams({ 
          ...radioSelected === "Plate Number" ? { plate_number: inputPlateNumber, type: "plate_number" } :
          { mv_file_number: inputPlateNumber, type: "mv_file_number" }
        });
     
        const result = await checkPlateNumberAvailability(params);
        setIsLoading(false);
        setPlateNumberAvailability(result);
        setErrorMessage("");
      } catch (error) {
        const errorMsg = JSON.parse(error.message);
        setIsLoading(false);
        if (errorMsg.code === 500) {
          console.log(errorMsg)
          setErrorMessage(errorMsg.message);
        } else if (errorMsg[0]) {
          setErrorMessage(errorMsg[0].detail);
        } else {
          setErrorMessage(errorMsg.message);
        }
      }
  };
  
  const onChange = (e) => {
    setRadioSelected(e.target.value);
  };

  return (
    <MainLayout>
      <StyledLeftLayout style={{ color: "#1565c0"}}>
        <LeftContainer>
          <div className='top-content'>
            <h2>Land Transportation Office</h2>
            <h1>License Plate Replacement Inquiry</h1>
          </div>
          <div className='bottom-content'>
            <StyledButtonShadow>
              LTO 123
              <StyledDivButton>
                <StyledButton>
                  LTO 123
                </StyledButton>
              </StyledDivButton>
            </StyledButtonShadow>
            <h3 style={{ width: "90%" }}>
              {
                !plateNumberAvailability?.status &&
                // "This interactive website is designed to provide information on the availability of paid and unclaimed LTO replacement license plates (green to white) from 2015."
                "This site is designed to provide information on the availability of paid and unclaimed LTO replacement license plates (from green to white) from 2015. Additional distribution sites will be available soon."              }
            </h3>
          </div>
        </LeftContainer>
      </StyledLeftLayout>
      <RightLayout>
        <StyledContent>
          {
            !isSearch && <>
              <Col style={{ width: "100%", marginBottom: 50 }}>
                <Typography style={{ fontSize: 30, color: "white" }}>Select an Option</Typography>
              
                <Row style={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <button onClick={() => setIsOpenDropdown(!isOpenDropdown) } type='button' style={{ display: "flex", alignItems: "center", background: "white", borderRadius: 4, height: 60, padding: 20, cursor: "pointer" }}>
                    <CaretDownFilled style={{ color: "gray" }} />
                  </button>
                  <StyledAntdSelect 
                    open={isOpenDropdown} 
                    ref={selectFieldRef} 
                    defaultValue="Green Plate Replacement"
                    getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                    style={{
                      width: 400,
                    }}
                    onChange={(e) => {
                      setIsOpenDropdown(false)
                      setSelectedValueOption(e);
                      if (e === "New MV License Plate") {
                        setRadioSelected("MV File Number");
                      }

                      if (e === "Green Plate Replacement") {
                        setRadioSelected("Plate Number");
                      }
                    }}
                  >
                    <Select.Option value="Green Plate Replacement">Green Plate Replacement</Select.Option>
                    <Select.Option value="New MV License Plate"></Select.Option>
                  </StyledAntdSelect>
                </Row>
              </Col>
              <StyledRadioGroupButton onChange={onChange} value={radioSelected}>
                <Radio value={"Plate Number"} disabled={selectedValueOption === "New MV License Plate"}>Plate Number</Radio>
                <Radio value={"MV File Number"} disabled={selectedValueOption === "Green Plate Replacement"}>MV File Number</Radio>
              </StyledRadioGroupButton>
            </>
          }
          <h3>
            {
              plateNumberAvailability && "Hello,"
            } 
          </h3>
          <StyledForm  
            isSearch={isSearch}
            onSubmit={getPlateNumberAvailability}
          >
            {
              isLoading && 
              <StyledSpinContainer style={{ position: "absolute", inset: 0, display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Spin spinning={isLoading} />
              </StyledSpinContainer>
            }
            <StyledInput 
              style={{ 
                  backgroundColor: plateNumberAvailability?.status ? plateNumberAvailability?.status.includes("available at") ? "#80f5be" : "#929292" : "white"
              }} 
              placeholder={radioSelected === "MV File Number" ? "0000-11111222222" : "AAA111"}
              type="text"
              onChange={(e) => setInputPlateNumber(e.target.value)}
            />
            {
              !isSearch && <StyledSearchButton type='submit'>Search</StyledSearchButton>
            }
          </StyledForm>
          <span style={{ color: "rgb(182, 182, 180)"}}>Note: No space in between. Please follow format indicated.</span>
          {
            errorMessage && <span style={{ color: "red" }}>{errorMessage}</span>
          }
          {
            plateNumberAvailability && 
            <StyledStatusResponse>
              {
                plateNumberAvailability.status?.includes("available at") ? 
                <div style={{ display: "flex", flexDirection: "column", gap: 30 }}>
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: slicePortionText(plateNumberAvailability).split(".")[0]
                    }}    
                  />
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: slicePortionText(plateNumberAvailability).split(".")[1]
                    }}    
                  />
                </div>
                : plateNumberAvailability?.status?.includes("lto.gov.ph") ?
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: slicePortionTextLtoGovPh(plateNumberAvailability)
                    }}    
                  />
                : <h3>{plateNumberAvailability?.status}</h3>
              }
            </StyledStatusResponse>
          }
        </StyledContent>
        {/* { plateNumberAvailability?.status?.includes("available at") && <StyledNote>Note: kindly <span style={{ color: "#ffff49"}}>surrender your OLD License Plate</span> to claim your new license plate(s). Please prepare an authorization letter should the vehicle owner is not available to pick up.</StyledNote>} */}
        { plateNumberAvailability?.status?.includes("available at") && <StyledNote>Note: Kindly bring your Certificate of Registration and Official Receipt to claim. Please prepare an authorization letter should the vehicle owner is not available to pick up.</StyledNote>}
        <StyledDataPrivacy>{ !plateNumberAvailability?.status && "Data Privacy: This site will not collect any personal information." }</StyledDataPrivacy>
      </RightLayout>
    </MainLayout>
  )
}

export default Home

const StyledAntdSelect = styled(Select)`
  /* width: 33px !important;
  > .ant-select-selector {
    height: 50px !important;
  } */
  
  .ant-select-arrow {
    display: none;
  }

  .ant-select-selector {
    border-radius: 4px !important;
    height: 60px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
      font-size: 30px;
    }
  }

  @media only screen and (max-width: 800px){

    & {
      width: 220px !important;
    }

    & .ant-select-selection-item {
      z-index: 1;
      font-size: 15px !important;
    }
  }
`

const StyledRadioGroupButton = styled(Radio.Group)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .ant-radio-wrapper-disabled {
    > span {
      color: gray;
    }
  }

  > :where(.css-dev-only-do-not-override-igyh80).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background: white;
    border-color: white;
  }

  > :where(.css-dev-only-do-not-override-igyh80).ant-radio-wrapper .ant-radio-inner::after {
    background: gray;
  }

  > .ant-radio {
    font-size: 30px;
  }


  > .ant-radio-wrapper {
    font-size: 30px;
    color: white;
  }

  @media only screen and (max-width: 800px){

    > .ant-radio {
      font-size: 14px;
    }


    > .ant-radio-wrapper {
      font-size: 14px;
      color: white;
    }
  }
`

const StyledForm = styled.form`
  position: relative;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  
  width: ${({isSearch}) => isSearch ? "" : "100%" } ;
`

const StyledInput = styled.input`
    text-align: center;
    padding: 15px;
    border-radius: 8px;
    outline: none;
    font-size: 28px;
    border:none;
    flex: 1;

    @media only screen and (max-width: 800px){
      font-size: 20px;
    }

    &::placeholder {
      text-align: center;
    }
`

const StyledSearchButton = styled.button`
    text-align: center;
    padding: 20px 60px;
    border-radius: 50px;
    outline: none;
    font-size: 28px;
    border:none;
    cursor: pointer;

    @media only screen and (max-width: 800px){
      font-size: 20px;
    }

    &:hover {
      background-color: #b1ada9;
    }
`
const StyledContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  color: white;
  margin-bottom: 20px;

  @media only screen and (max-width: 800px){

    & h2 {
      font-size: 19px;
    }

    & h3 {
      font-size: 20px;
    }
  }
`
const StyledNote = styled.h3`
  padding: 0px 40px;
  text-align: center;
  color: white;

  @media only screen and (max-width: 800px){
    padding: 0px;
    font-size: 18px;
  }
`

const StyledDataPrivacy  = styled.h4`
    color: #757575;
    text-align:center;
    
    @media only screen and (max-width: 800px){
      font-size: 18px;
    }
`

const StyledStatusResponse = styled.div`
  text-align: center;
`

const StyledDivButton = styled.div`
    position: absolute;
    width: 100%;
    top: 50%;
    left: 28%;
`
const StyledButton =styled.button`
    border-radius: 6px;
    font-size: 25px;
    margin-bottom: 30px;
    position: relative;
    padding: 15px;
    border: none;
    color: black;
    font-weight: 600;
    border:2px solid black;
`
const StyledButtonShadow = styled.div`
    margin-bottom: 30px;
    position: relative;
    padding: 15px 20px;
    font-size: 25px;
    border-radius: 6px;
    border: none;
    background-color: #d3ffd3;
    color: #5881585f;
    font-weight: 600 !important;

    @media only screen and (max-width: 800px){
      display: none;
    }
`

const StyledSpinContainer = styled.div`
  padding: 30px 50px;
  text-align: center;
  background: rgba(30, 49, 85, 0.301);
  border-radius: 4px;
`

const StyledLeftLayout = styled(LeftLayout)`
  display: none !important;
`

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;

  .top-content {

    h1 {
      font-size: 56px;
      line-height: 1em;
    }
  }

  .bottom-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    place-items: flex-start;
  }

  @media only screen and (max-width: 800px){
    /* display: none; */
    margin-top: 50px;
    .top-content {
      display: flex;
      flex-direction: column;
      gap: 20px;

      & h1 {
        font-size: 25px !important;
        line-height: 1.3em;
      }

      & h2 {
        font-size: 20px !important;
        line-height: 1.3em;
      }
    }

    .bottom-content {
      display: none !important;
    }

  }
  
`