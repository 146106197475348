export const slicePortionText = (plateNumberAvailability) => {
  const text = plateNumberAvailability.status;
  const startText = "at ";
  const startIndex = text.indexOf(startText) + startText.length;
  const endText = ".";
  const endIndex = text.indexOf(endText, startIndex);
  const slicedText = text.slice(startIndex, endIndex);

  const extractedText1 = text.slice(startIndex, endIndex);
  const underlinedText1 = text.replace(slicedText, `<h3 style="text-decoration:underline;">${extractedText1}</h3>`);
  return underlinedText1;
};

export const slicePortionTextLtoGovPh = (plateNumberAvailability) => {
  const text = plateNumberAvailability.status;
  const startText = "(";
  const startIndex = text.indexOf(startText) + startText.length;
  const endText = ")";
  const endIndex = text.indexOf(endText, startIndex);
  const slicedText = text.slice(startIndex, endIndex);

  const extractedText1 = text.slice(startIndex, endIndex);
  const underlinedText1 = text.replace(slicedText, `<a href="${extractedText1}" target="_blank" style="text-decoration:undlerine; color: white;">${extractedText1}</a>`);
  return underlinedText1;
}; 