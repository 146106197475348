import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import FAQs from './pages/FAQs/FAQs';
import { ConfigProvider } from 'antd';

function App() {
  return (
      <ConfigProvider
        theme={{
          components: {
            Radio: {
              radioSize: 25,
              dotSize: 12,
              buttonCheckedBg: "red !important"
              /* here is your component tokens */
            },
          },
        }}
      >
      <Routes>
        <Route path="/" element={ <Home/> }></Route>
        <Route path="/FAQs" element={ <FAQs/> }></Route>
      </Routes>
    </ConfigProvider>
  );
}

export default App;
