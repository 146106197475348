import React from 'react'
import { styled } from 'styled-components'

const LeftLayout = ({ children, placeContent, style }) => {
  return (
    <StyledLeftLayout style={style}>
      {children}
    </StyledLeftLayout>
  )
}

export default LeftLayout

const StyledLeftLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  padding: 120px 50px 50px 50px;

  @media only screen and (max-width: 800px){
    padding: 100px 50px 50px 50px;
  }
`