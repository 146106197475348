import React from 'react'
import Header from './Header'
import { styled } from 'styled-components'

const MainLayout = ({ children }) => {
  return (
    <div style={{ position: "relative"}}>
      <Header/>
      <Container style={{ display: "flex", height: "100vh"}}>
        {children}
      </Container>
    </div>
  )
}

export default MainLayout;

const Container = styled.div`
  @media only screen and (max-width: 800px){
    flex-direction: column;
  }
`