import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import { MenuOutlined } from '@ant-design/icons';
import { useState } from 'react';

const Header = () => {
  const [isShowNav, setisShowNav] = useState(false);

  return (
    <StyledHeader>
      <StyledImage src="/images/lto-logo.png" alt='lto-logo'/>
      <MenuIcon onClick={() => setisShowNav(!isShowNav)}/>
      <StyledNav is_show_nav={isShowNav.toString()}>
        <LeftNav>
          <StyledLink to={"/"}>
            Home  
          </StyledLink>
          <StyledLink to={"/FAQs"}>
            FAQs
          </StyledLink>
        </LeftNav> 
        <RightNav>
          <StyledLink to={"/"} style={{ color: "white"}}>
            LTO GOVPH
          </StyledLink>
          <StyledLink to={"/"} style={{ color: "white"}}>
            LTMS PORTAL
          </StyledLink>
        </RightNav>
      </StyledNav>
    </StyledHeader>
  );
};
export default Header;

const StyledHeader = styled.header`
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: none;
  padding: 20px 50px;
  gap: 40px;
  font-size: 20px;
  font-weight: 600;

  @media only screen and (max-width: 800px){
    font-size: 17px;
    gap: 10px;
    display: flex;
    justify-content: space-between;
  }
`

const MenuIcon = styled(MenuOutlined)`
  display: none;
  font-size: 25px;

  @media only screen and (max-width: 800px){
    display: flex;
  }
`

const StyledNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: 800px){
    display: ${({ is_show_nav }) => is_show_nav === "true" ? "flex" : "none" };
    position: absolute;
    top: 70%;
    left: 0;
    gap: 10px;
    flex-direction: column;
  }
`

const LeftNav = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 40px;
`
const RightNav = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;

  @media only screen and (max-width: 800px){
    color: black !important;
  }
`
const StyledImage = styled.img`
  width: 80px;

  @media only screen and (max-width: 800px){
    width: 60px;
  }
`