import React from 'react'
import { styled } from 'styled-components'

const RightLayout = ({ children, style }) => {
  return (
    <StyledRightLayout style={style}>
      {children}
    </StyledRightLayout>
  )
}

export default RightLayout

const StyledRightLayout = styled.div`
  background-color: #101035;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 120px 50px 50px 50px;

  @media only screen and (max-width: 800px){
    height: 100%;
    padding: 20px 40px 20px 40px;
  }
`