import axios, { AxiosError } from "axios";

const baseURL= process.env.REACT_APP_API_URL;

export const httpApi = axios.create({});

httpApi.interceptors.request.use((request) => {
  request.baseURL = baseURL
  request.headers = { 
    ...request.headers, 
  };
  return request;
});

httpApi.interceptors.response.use(undefined, (error = AxiosError) => {
  if (error.response) {
    throw new Error(
      error.response.status === 500 ? 
      JSON.stringify({ code: 500, message: error.response.data.errors[0].detail || "Something went wrong. Please try again later." }) : 
      JSON.stringify(error.response?.data.errors || error.response?.data)
    );
  } else {
    throw new Error(
      JSON.stringify({ message: "Something went wrong. Please try again later." })
    );
  }
});